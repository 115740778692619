import React, { PropsWithChildren } from "react";
import { Link, Outlet, useLoaderData } from "@remix-run/react";
import { maxTrialCredits, useUser } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { ChevronDown } from "lucide-react";
import { json, LoaderFunctionArgs } from "@remix-run/cloudflare";
import { existsConfig } from "@/lib/config.server";
import { REF_KEY, refSessionStorage } from "@/lib/cookie.server";

export async function loader({ request, context, params }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const ref = url.searchParams.get("ref");
  const referer = request.headers.get("Referer");

  const cookie = request.headers.get("Cookie");
  const session = await refSessionStorage.getSession(cookie);

  const refData = ref ?? referer;
  if (refData) {
    if (!session.has(REF_KEY)) {
      session.set(REF_KEY, refData);
    }
  }

  return json({
    DEV: existsConfig(context, "DEV")
  }, {
    headers: {
      "Set-Cookie": await refSessionStorage.commitSession(session),
    }
  });
}

const PublicNavbar = () => {
  const {  DEV } = useLoaderData<typeof loader>();
  const user = useUser();
  // const [added, setAdded] = useState(false);

/*  useEffect(() => {
    if (!DEV && !added) {
      const tawkto = document.createElement("script");
      tawkto.innerHTML = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                        Tawk_API.visitor={};
                        (function(){
                        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                        s1.async=true;
                        s1.src='https://embed.tawk.to/64c51912cc26a871b02bfb7b/1h6gujsof';
                        s1.charset='UTF-8';
                        s1.setAttribute('crossorigin','*');
                        s0.parentNode.insertBefore(s1,s0);
                        })();
                `;
      document.body.append(tawkto as any);

      const lemonTracking = document.createElement("script");
      lemonTracking.innerHTML = `window.lemonSqueezyAffiliateConfig = { store: "rank-hub" };`;
      document.body.append(lemonTracking as any);

      const lemonTracking2 = document.createElement("script");
      lemonTracking2.defer = true;
      lemonTracking2.src = "https://lmsqueezy.com/affiliate.js"
      document.body.append(lemonTracking2 as any);
      setAdded(true);
    }
  }, []);*/

  return (
    <div className="h-full flex-grow flex flex-col">
      <header
        className="fixed top-0 left-0 right-0 h-auto z-20 py-4 bg-gray-800 shadow-sm text-gray-200 px-8 md:px-24">
        <div className="max-w-7xl mx-auto flex">
          <div className="w-0 flex-grow flex gap-8 items-center">
            <Link to="/#home"
                  className="bg-left bg-no-repeat"
              // style={{backgroundImage: `url(/horizontal_layout.svg) `}}
            >
              <h1 className="font-bold text-2xl text-gray-100">
                Rank-Hub
              </h1>
            </Link>

            <Link to="/#pricing" className="font-semibold hidden sm:block">Pricing</Link>
            <Link to="/#faq" className="font-semibold hidden sm:block">FAQs</Link>
            {/*<Link to="/blog" className="font-semibold hidden sm:block">Blog</Link>*/}
            {/*
                        <DropDownItem name="Features">
                            sdf
                        </DropDownItem>
*/}
            {/*
                        <DropDownItem name="Use Cases">
                            sdf
                        </DropDownItem>
*/}
            {/*<DropDownItem name="Compare Rank-Hub With...">
                            <div className="flex flex-col">
                                <Link to="abc" className="p-4">UserList</Link>
                                <Link to="abc" className="p-4">Intercom</Link>
                                <Link to="abc" className="p-4">Customer.io</Link>
                                <Link to="abc" className="p-4">Drip</Link>
                                <Link to="abc" className="p-4">ConvertKit</Link>
                                <Link to="abc" className="p-4">Mailchimp</Link>
                                <Link to="abc" className="p-4">UserPilot</Link>
                                <Link to="abc" className="p-4">Appcues</Link>
                                <Link to="abc" className="p-4">Hubspot</Link>
                                <Link to="abc" className="p-4">Userflow</Link>
                            </div>
                        </DropDownItem>*/}
            {/*<Link to="blog" className="font-semibold hidden sm:block">Blog</Link>*/}
          </div>
          <div className="flex gap-4 items-center">
            {user ?
              <p className="italic font-semibold hidden md:block">Hi, welcome back! 👉</p>
              :
              <Button to="/login" variant={"outline-dark"}>
                Login
              </Button>
            }
            <Button to={user ? "/app" : "/signup"}>
              {user ? "Open App" : `Get ${maxTrialCredits} credits for free`}
            </Button>
          </div>
        </div>
        {/*
            <div
                className="bg-gray-900 fixed inset-0 bottom-auto z-20">
                <div className="flex justify-between py-3 items-center max-w-6xl mx-auto px-3">
                    <div className="flex w-0 2xs:w-auto 2xs:flex-grow self-stretch bg-left my-1.5 bg-no-repeat"
                         style={{backgroundImage: `url(/horizontal_layout_dark_bg.svg) `}}>
                        <h2 className="font-bold text-gray-900 text-xl pl-2">Rank-Hub</h2>
                    </div>
                    <div className="flex items-center gap-8">
                        <Link to="#home" className="text-white font-semibold hidden sm:block">Home</Link>
                        <Link to="#pricing" className="text-white font-semibold hidden sm:block">Pricing</Link>
                        <Link to="#faq" className="text-white font-semibold hidden sm:block">FAQ</Link>
                        <Link to="blog" className="text-white font-semibold hidden sm:block">Blog</Link>
                    </div>
                </div>
            </div>
*/}
      </header>
      <div className="flex-grow flex flex-col">
        <Outlet />
        <div className="text-gray-400 bg-gray-800 px-8 border-t border-gray-700">
          {/*<div className="max-w-6xl py-8 grid grid-cols-1 md:grid-cols-3 gap-16 mx-auto">
                    <div className="flex flex-col gap-3 flex-grow">
                        <h3 className="font-bold text-gray-200 mb-2">Product</h3>
                        <Link to="/legal/privacy" className="hover:text-gray-300">Features</Link>
                        <Link to="/legal/terms-of-service" className="hover:text-gray-300">Guides</Link>
                        <Link to="/legal/acceptable-use-policy" className="hover:text-gray-300">About</Link>
                        <Link to="/legal/acceptable-use-policy" className="hover:text-gray-300">Case Studies</Link>
                    </div>
                    <div className="flex flex-col gap-3 flex-grow">
                        <h3 className="font-bold text-gray-200 mb-2">Compare</h3>
                        <Link to="/legal/privacy" className="hover:text-gray-300">vs. Userlist</Link>
                        <Link to="/legal/terms-of-service" className="hover:text-gray-300">vs. Customer.io</Link>
                        <Link to="/legal/acceptable-use-policy" className="hover:text-gray-300">vs. Mailchimp</Link>
                        <Link to="/legal/acceptable-use-policy" className="hover:text-gray-300">vs. Convertkit</Link>
                    </div>
                    <div className="flex flex-col gap-3 flex-grow">
                        <h3 className="font-bold text-gray-200 mb-2">Legal</h3>
                        <Link to="/legal/privacy-policy" className="hover:text-gray-300">Privacy & Cookie Policy</Link>
                        <Link to="/legal/terms-of-service" className="hover:text-gray-300">Terms of Service</Link>
                        <Link to="/legal/acceptable-use-policy" className="hover:text-gray-300">Acceptable Use
                            Policy</Link>
                    </div>
                </div>*/}
          <div className="max-w-6xl py-8 gap-6 md:gap-16 mx-auto flex flex-col md:flex-row justify-evenly">
            <Link to="/legal/privacy-policy" className="hover:text-gray-300">Privacy & Cookie Policy</Link>
            <Link to="/legal/terms-of-service" className="hover:text-gray-300">Terms of Service</Link>
            <Link to="/legal/acceptable-use-policy" className="hover:text-gray-300">Acceptable Use
              Policy</Link>
            <Link to="/legal/imprint" className="hover:text-gray-300">Imprint & Contact</Link>
          </div>

        </div>
      </div>
    </div>
  );
};

const DropDownItem = (props: PropsWithChildren<{ name: string }>) => {
  return (
    <div className="font-semibold hidden sm:block cursor-pointer relative group">
      {props.name} <ChevronDown className="ml-1 text-sm" />
      <div className="pt-4 duration-100 opacity-0 scale-0 -translate-y-4 group-hover:translate-y-0
             group-hover:scale-100 group-hover:opacity-100 absolute w-full left-1/2 -translate-x-1/2 top-100%">
        <div
          className="bg-white px-4 py-2 rounded shadow-md border-t border-gray-100">
          {props.children}
        </div>
      </div>
    </div>
  );
};


export default PublicNavbar;
